
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  min-height:100vh;
  width: 100vw;
  background: linear-gradient(318.96deg, rgba(0, 0, 0, 0.4) -1.9%, rgba(36, 36, 36, 0.4) 105%), #343434;
}


form {
  /* min-width: 500px; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.wf-title {
  color: white;
  font-family: Ubuntu;
  font-weight: 700;
  letter-spacing: 0,37px;
  font-size: 1.3rem!important;
}

#payment-message {
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-header {
  color: #ffffff;
  font-family: Ubuntu;
  text-align: center;
  font-weight: 500;
}

#payment-element {
  margin-bottom: 24px;
}

#payment-form {
  background-color: #ffffff;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    /* width: 80vw; */
    min-width: initial;
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.facebookLoginBtn {
  margin-top: 3px;
  background-color: rgb(18, 79, 178);
  color: rgb(255, 255, 255);
  height: 50px;
  width: 240px;
  border: none;
  text-align: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s;
  font-family: Roboto, arial, sans-serif;
  cursor: pointer;
  user-select: none;
}

.wf-button {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  text-align: center; 
  line-height: 0px;
  width: 217px;
  height: 56px;

  background: linear-gradient(92.98deg, #2957FE 2.13%, #F43187 97.63%);
  border-radius: 39px;

}

.preview-btn {
  border-top-left-radius: 12px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 12px;
  margin-top: 10px;
  height: 27px;
}

.buy-btn {
  border-top-left-radius: 25px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 25px;
  height: 51px;
}

.wf-header {
  /* background: rgba(29, 29, 29, 0.94); */
  /* box-shadow: 0px 0.5px 0px rgba(255, 255, 255, 0.2); */
  backdrop-filter: blur(10px);
}

.download-text {
  font-family: 'Ubuntu';
  font-weight: 400;
  font-size: 0.9rem;
}

.preview-img-div {
  background: radial-gradient(50% 50% at 50% 50%, rgba(81, 69, 46, 0.7) 56.54%, rgba(32, 32, 32, 0) 100%);
}

.preview-image {
  position: absolute;
  width: 65%;
  height: auto;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  border-radius: 29px;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}


.container {
  /* position: relative; */
  max-width: 100%;
  overflow: hidden;
}

.wfgallery {
  max-width: 100%;
}

.ellipse {
  position: absolute;
  width: 241px;
  height: 197.88px;
  background: radial-gradient(50% 50% at 50% 50%, #3D67FC 0%, rgba(61, 103, 252, 0) 100%);
  filter: blur(90px);
}

.ellipse-1 {
  left: -137px;
  top: 430px;
}

.ellipse-2 {
  left: 94px;
  top: 100.49px;
  background: radial-gradient(50% 50% at 50% 50%, #B747FC 0%, rgba(183, 71, 252, 0) 100%);
}

.ellipse-3 {
  width: 165px;
  height: 110px;
  left: 279px;
  top: 700px;
  background: radial-gradient(50% 50% at 50% 50%, #FFB45D 0%, rgba(255, 180, 93, 0) 100%);
}

.ellipse-4 {
  width: 165px;
  height: 135.48px;
  left: -100px;
  top: 591px;
  background: radial-gradient(50% 50% at 50% 50%, #A8FF72 0%, rgba(168, 255, 114, 0) 100%);
}

.ellipse-5 {

  left: 281px;
  top: 330px;
  background: radial-gradient(50% 50% at 50% 50%, #41FFFF 0%, rgba(65, 255, 255, 0) 100%);
}

.ellipse-6 {
  
  left: 281px;
  top: 900px;
  background: radial-gradient(50% 50% at 50% 50%, #41FFFF 0%, rgba(65, 255, 255, 0) 100%);
}

.ellipse-7 {
  left: -137px;
  top: 1100px;
}

#background-div {
  background: #000000;
  z-index: -1;
  color: #FFFFFF;
  height: 100%
}

.info-button {
  border-radius: 50%;
  background-color: transparent;
  border: none;
  /* padding: 5px; */
  /* float: right; */
  /* margin-left: 20px; */
  width: 10px;
  cursor: pointer;
}

.info-icon {
  font-size: 18px;
}

.add-btn {
  width: 210px;
  height: 51px;
  background: #FFFFFF;
  border-radius: 14px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
}



.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
